import React, { useReducer, useState } from 'react';
import { Box, Button, Card, CardContent, Container, Typography, useTheme } from '@mui/material';
import Page from '../../../../components/Page';
import CaseEventsListTable from './CaseEventsListTable';
import { useNavigate } from 'react-router-dom';
import { CreateCaseDialog } from '../CreateCase/CreateCaseDialog';
import { useQuery } from '@apollo/client';
import { FIND_CASE_EVENTS } from '../../../../gql';
import { CustomAppBar } from '../../../../components/CustomAppBar';
import {
  CaseShippingStatusType,
  EventType,
  ICase,
  Permission,
  UserRoleType,
} from '@workflow-nx/common';
import { CaseEventTableFilter } from './CaseEventTableFilter';
import { Heading } from '../../../../components/Heading';
import SearchInput from '../../../../components/SearchInput';
import { ListCasesEventsReducer, ListCaseEventsStateType } from './ListCasesEvent.reducer';
import useAuth from '../../../../hooks/useAuth';
import { ExportCasesButton } from '../ExportCasesButton';
import { testing } from '@workflow-nx/utils';
import { Globals } from '../../../../layouts/DashboardLayout';
import { CreateEventsDialog } from './CreateEventsDialog';
import { useSnackbar } from 'notistack';

interface IListCaseEventsQueryVariables {
  search: string;
  orderBy: { surgeryDate: string };
  surgeonFilter: number[];
  fieldRepFilter: number[];
  assignedToFilter: number[];
  organizationFilter: number[];
  shippingStatusFilter: CaseShippingStatusType[];
  eventsFilter: EventType[];
  skip: number;
  take: number;
}

function getDefaultState(
  hasRole: ((roles: UserRoleType[]) => boolean) | undefined,
  currentUserId: number | undefined,
) {
  const cache = localStorage.getItem('ListProductionFocusCases.cache');

  let defaultVariables: IListCaseEventsQueryVariables;
  let initialState: ListCaseEventsStateType;

  if (!cache) {
    const defaultFieldRepId =
      hasRole && hasRole([UserRoleType.FieldRep]) ? currentUserId : undefined;

    defaultVariables = {
      assignedToFilter: [] as number[],
      organizationFilter: [] as number[],
      surgeonFilter: [] as number[],
      fieldRepFilter: defaultFieldRepId ? [defaultFieldRepId] : [],
      shippingStatusFilter: [],
      eventsFilter: [],
      orderBy: { surgeryDate: 'asc' },
      search: '',
      skip: 0,
      take: Globals.DefaultListPageSize,
    };

    initialState = {
      assignedTo: '',
      organization: '',
      surgeon: '',
      fieldRep: defaultFieldRepId ? defaultFieldRepId.toString() : '',
      events: [],
      shippingStatuses: [],
      orderBy: { surgeryDate: 'asc' },
      search: '',
      pageNumber: 0,
      pageSize: Globals.DefaultListPageSize,
    };

    localStorage.setItem(
      'ListProductionFocusCases.cache',
      JSON.stringify({ variables: defaultVariables, state: initialState }),
    );
  } else {
    const hydratedCache: {
      variables: IListCaseEventsQueryVariables;
      state: ListCaseEventsStateType;
    } = JSON.parse(cache);
    defaultVariables = hydratedCache.variables;
    initialState = hydratedCache.state;
  }
  return [defaultVariables, initialState];
}

const ListCaseEventsView = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { hasRole, hasPermission, user: currentUser } = useAuth();
  const [showCaseCreateDialog, setShowCaseCreateDialog] = useState(false);
  const [showCreateEventsDialog, setShowCreateEventsDialog] = useState(false);
  const [defaultVariables, initialState] = getDefaultState(hasRole, currentUser?.userId);
  const [bulkCasesSelected, setBulkCasesSelected] = useState<string[]>([]);
  const { loading, data, refetch } = useQuery(FIND_CASE_EVENTS, {
    variables: defaultVariables,
    fetchPolicy: 'cache-and-network',
  });

  const [state, dispatch] = useReducer(
    ListCasesEventsReducer(refetch),
    initialState as ListCaseEventsStateType,
  );

  const actionButtons = [];
  let showBulkChangeButton = false;

  const checkSelectedProductionEvents = () => {
    const cases = (data?.caseEvents?.cases as any[]).filter((caseEvent) =>
      bulkCasesSelected.includes(caseEvent?.caseId?.toString()),
    );

    if (cases.every((ele) => ele.eventType === cases[0].eventType)) {
      setShowCreateEventsDialog(true);
    } else {
      enqueueSnackbar('All the checked cases must have the same event type', {
        variant: 'error',
      });
    }
  };

  if (
    !hasRole?.([
      UserRoleType.Operations,
      UserRoleType.QualityEngineer,
      UserRoleType.SiteAdministrator,
      UserRoleType.SupplyChain,
    ])
  ) {
    navigate('/app/cases/');
  }
  if (hasPermission?.([Permission.ManageCase])) {
    actionButtons.push(
      <Button
        data-test-id={testing.toKebabCase('Create Case') + '-button'}
        variant={'contained'}
        color={'secondary'}
        onClick={() => setShowCaseCreateDialog(true)}
      >
        Create Case
      </Button>,
    );
    showBulkChangeButton = true;
  }
  if (hasPermission?.([Permission.ReportExportCaseData])) {
    actionButtons.push(<ExportCasesButton />);
  }

  return (
    <>
      <Page title={'Cases'}>
        <Container maxWidth={false}>
          <CustomAppBar title={`Cases`} actions={actionButtons} />

          <Box display={'flex'}>
            <Box width={250} mr={2}>
              <Box display={'flex'} alignItems={'center'} height={40} mb={1}>
                <Heading label={'Filters'} />
              </Box>
              <Box mb={2} textAlign={'right'} width={'100%'}>
                {showBulkChangeButton ? (
                  <Button
                    data-test-id={testing.toKebabCase('Create Case') + '-button'}
                    variant={'contained'}
                    color={'secondary'}
                    onClick={() => checkSelectedProductionEvents()}
                    disabled={bulkCasesSelected.length < 1}
                    fullWidth
                  >
                    Bulk Change
                  </Button>
                ) : null}
              </Box>
              <Card>
                <CardContent style={{ minWidth: 150 }}>
                  <CaseEventTableFilter state={state} dispatch={dispatch} loading={loading} />
                </CardContent>
              </Card>
            </Box>

            <Box width={'calc(100% - 266px)'}>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
                <Heading label={`Results (${data?.caseEvents?.count ?? 0} cases)`} />
                <Box width={250} bgcolor={theme.palette.background.paper}>
                  <SearchInput
                    value={state.search ?? ''}
                    onChange={(value: string) => dispatch({ type: 'SEARCH_CHANGED', data: value })}
                  />
                </Box>
              </Box>
              <Box>
                <Card>
                  <CardContent style={{ height: 'calc(100vh - 228px)', display: 'flex' }}>
                    {data?.caseEvents?.cases.length ? (
                      <CaseEventsListTable
                        loading={loading}
                        orderBy={state.orderBy}
                        dispatch={dispatch}
                        caseEvents={data?.caseEvents?.cases}
                        page={state.pageNumber}
                        rowsPerPage={state.pageSize}
                        totalCaseEventCount={data?.caseEvents?.count ?? 0}
                        onSelected={(selectedCases) => {
                          setBulkCasesSelected(selectedCases);
                        }}
                        selectedCases={bulkCasesSelected}
                      />
                    ) : (
                      <Box
                        margin={'auto'}
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                      >
                        <Typography variant="h4">No results found</Typography>
                        <Box my={1} />
                        <Button
                          variant="outlined"
                          color={'secondary'}
                          onClick={() => {
                            dispatch({ type: 'RESET_FILTERS' });
                          }}
                        >
                          Reset Filters
                        </Button>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </Box>
        </Container>
      </Page>

      {showCaseCreateDialog ? (
        <CreateCaseDialog
          open={showCaseCreateDialog}
          onCreate={(caseId) => {
            setShowCaseCreateDialog(false);
            navigate(`/app/cases/${caseId}`);
          }}
          onClose={() => {
            setShowCaseCreateDialog(false);
            refetch();
          }}
        />
      ) : null}
      {showCreateEventsDialog ? (
        <CreateEventsDialog
          activeCases={(data?.caseEvents?.cases as any[]).filter((caseEvent) =>
            bulkCasesSelected.includes(caseEvent?.caseId?.toString()),
          )}
          open={showCreateEventsDialog}
          onClose={(resetTableChecks = false) => {
            if (resetTableChecks) {
              setBulkCasesSelected([]);
            }
            setShowCreateEventsDialog(false);
            refetch();
          }}
        />
      ) : null}
    </>
  );
};

export default ListCaseEventsView;
