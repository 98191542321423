import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import {
  CarrierType,
  EventType,
  format,
  getEventAssetType,
  getSubsequentManualEvents,
  ICase,
  IEvent,
  isTrackableEvent,
} from '@workflow-nx/common';
import { InputConfigRHF, InputRHF, InputTypeRHF, ProgressButton } from '@workflow-nx/ui';
import { file } from '@workflow-nx/utils';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { ComponentProps } from 'react';
import { Control, FieldValues, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import CustomDialog from '../../../components/CustomDialog';
import {
  createEventsDialogFormValues,
  ICreateEventDialogFormValues,
} from '../../../extras/formValues';
import { createEventDialogSchema } from '../../../extras/schemas';
import { CREATE_EVENT } from '../../../gql';

export interface CreateEventFormType extends FieldValues {
  occurredAt: Date;
  eventType: EventType;
  description: string;
  note: string;
  trackingNumber: string | null;
  purchaseOrder: string | null;
  carrierType: CarrierType | null;
}

export function CreateEventDialog(props: {
  activeCase: ICase;
  events?: IEvent[];
  onClose: (shouldUpdate: boolean, eventType?: EventType) => void;
  open: boolean;
}) {
  const [createEvent, { loading: loadingCreateEvent }] = useMutation(CREATE_EVENT);

  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: createEventsDialogFormValues(),
    resolver: yupResolver(
      createEventDialogSchema,
    ) as unknown as Resolver<ICreateEventDialogFormValues>,
  });

  const eventType = watch('eventType');

  function getEventMenuItems(): { key: string; value: string }[] {
    const events = (props.events ?? []).filter(
      (event: IEvent) => event.eventType !== EventType.Exception,
    );
    const lastEvent = events.length > 0 ? events[0] : null;

    const manualEvents = getSubsequentManualEvents(
      lastEvent?.eventType,
      props.activeCase.vendorAssignments,
    );

    const menuItems: { key: string; value: string }[] = manualEvents.map((eventTypeValue) => {
      return {
        key: eventTypeValue,
        value: format.formatEventType(eventTypeValue as EventType),
      };
    });

    return menuItems;
  }

  const handleSubmitForm: SubmitHandler<ICreateEventDialogFormValues> = async (data) => {
    try {
      const occurredAt = data.occurredAt
        ? DateTime.fromJSDate(data.occurredAt).toISODate()
        : undefined;

      const res = await createEvent({
        variables: {
          caseId: props.activeCase.caseId,
          eventType: data.eventType,
          carrierType: data.carrierType,
          trackingNumber: data.trackingNumber,
          description: data.description,
          occurredAt,
          note: data.note,
          fileName: data.asset?.name,
          fileSize: data.asset?.size,
          fileMetadata: undefined,
        },
      });
      const signedUrl = res.data?.createEvent?.signedUrl;

      const eventId = res.data?.createEvent?.event?.eventId;

      const eventAssetId = res.data?.createEvent?.event?.assetId;

      if (signedUrl && eventId && eventAssetId) {
        await file.uploadFile(signedUrl, data.asset, (percentComplete) => {
          console.info(percentComplete);
        });
      }

      enqueueSnackbar('Event created', {
        variant: 'success',
      });

      props.onClose(true, data.eventType);
    } catch (err: any) {
      console.error(err);

      enqueueSnackbar('An error occurred creating the event', {
        variant: 'error',
      });
    } finally {
      reset();
    }
  };

  const loading = loadingCreateEvent;

  const formItems: InputConfigRHF[] = [
    {
      id: 'eventType',
      label: 'Event Type',
      input: InputTypeRHF.Select,
      menuItems: getEventMenuItems(),
    },
    {
      id: 'occurredAt',
      label: 'Occurred At',
      input: InputTypeRHF.Date,
    },
    {
      id: 'description',
      label: 'Description',
      input: InputTypeRHF.Text,
    },
    {
      id: 'carrierType',
      label: 'Carrier',
      input: InputTypeRHF.Select,
      menuItems: [
        { key: CarrierType.FedEx, value: 'FedEx' },
        {
          key: CarrierType.Mnx,
          value: 'MNX',
        },
        { key: CarrierType.Other, value: 'Other' },
      ],
    },
    {
      id: 'trackingNumber',
      label: 'Tracking Number',
      input: InputTypeRHF.Text,
    },
    {
      id: 'asset',
      label: 'Asset',
      input: InputTypeRHF.Asset,
    },
    {
      id: 'note',
      label: 'Note',
      input: InputTypeRHF.Text,
    },
  ];

  const getInputProps = (config: InputConfigRHF): ComponentProps<typeof InputRHF> => ({
    config: config,
    control: control as Control<FieldValues>,
    disabled: isSubmitting || loading,
    textFieldProps: {
      multiline: config.id === 'note' ? true : false,
    },
    selectFieldProps: {
      hideNone: true,
    },
    datePickerProps: {
      disableFuture: true,
    },
    dropzoneProps: {
      validFileExtensions: config.validFileExtensions,
    },
  });

  return (
    <CustomDialog
      maxWidth={'md'}
      open={props.open}
      title={`Create Production Event`}
      onClose={() => {
        reset();
        props.onClose(false);
      }}
      positiveActionButtons={[
        <ProgressButton
          variant={'contained'}
          disabled={isSubmitting || loading}
          onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
          label={'Create'}
          loading={isSubmitting || loading}
        />,
      ]}
    >
      <Grid container spacing={3}>
        {formItems.map((config) => {
          if (config.id === 'asset') {
            if (!eventType) return null;

            const eventAsset = getEventAssetType(eventType);

            if (!eventAsset) return null;

            config.label = format.formatAssetType(eventAsset.assetType);

            config.validFileExtensions = [eventAsset.fileExtension];
          }

          if (
            (config.id === 'trackingNumber' || config.id === 'carrierType') &&
            (eventType ? !isTrackableEvent(eventType) : true)
          )
            return null;

          return <InputRHF key={config.id} {...getInputProps(config)} />;
        })}
      </Grid>
    </CustomDialog>
  );
}
